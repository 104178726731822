.button{
  position: relative;
  border:1px solid #bbb;
  width:250px;
  // margin-left: 20px;
}

.button:hover{
  cursor: pointer;
}
.button-text{
  text-align: center;
  padding:20px 10px;
  font-size:27px;
  font-weight:100;
  color:#fff;
  transition: color 0.5s ease-in-out; 
  // border:1px solid red;
}
.button:hover .button-text{
  color:#111;
}

.button-background{
  background:#fff;
  position: absolute;
  top:0;
  width:0;
  height:100%;
  transition: width 0.5s ease-in-out; 
  z-index: -1;

}
.button:hover .button-background{
  width:100%;
}

.long-button { // add-on class for increased width, double the width for more text in a button
  width: 500px;
}

.no-left-padding {
  
}

@media only screen and (max-width: 1500px) {
  .button{
    width:17vw;
    height:5vw;
  }
  .button:hover{
    cursor: pointer;
  }
  .button-text{
    font-size: 1.8vw;
    padding:1.3vw;
  }
  .button:hover .button-text{
  }
  
  .button-background{
  }

  .long-button { 
    width: 17*2vw;
  }
}
@media only screen and (max-width: 600px) {
  .button{
    width:17vw;
    height:5vw;
  }
  .button:hover{
    cursor: pointer;
  }
  .button-text{
    font-size: 1.8vw;
    padding:1.1vw;
  }
  .button:hover .button-text{
  }
  
  .button-background{
  }
  .long-button { 
    width: 17*2vw;
  }
}