// *{border:1px solid red;}

#powered-by-container{
  // border:1px solid red;
  position: absolute;
  bottom:20vh;
  left:5vw;
  width:40rem;
  height:20rem;
  opacity:0;
  transition:1s ease-out;
  // overflow: hidden;
}

.powered-by-title{
  color:rgb(224, 224, 224);
  font-size: 40px;
  position: relative;
  width:288px;
  height:130px;
  margin-left: 0.7rem;
  padding-top: 5rem;
  font-weight: 500;
}

.powered-by-sponsors-container{
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.powered-by-sponsors-container a{
width:7rem;
height:7rem;
margin:7px;
border-radius: 10px;
overflow: hidden;
// border:2px solid;
opacity:0;
transition:1s ease-out;
// background-color: #fff;
}

.powered-by-sponsor-image{
  width:100%;
  height:100%;
  object-fit: contain;
  background-color: #fff;
  opacity:.9;
  border-radius: 25px;
  transition:.3s ease-in-out;
}
.powered-by-sponsor-image:hover{
  opacity:1;
}



@media only screen and (max-width: 1200px) {

#powered-by-container{
  bottom:calc(50vh - 30vw);
  left:2vw;
  width:50vw;
  height:50vw;
}

.powered-by-title{
  color:rgb(224, 224, 224);
  padding:4vw 2vw;
  margin-bottom:5px;
  font-size: 4vw;
  position: relative;
  width:30vw;
  height:10vw;
}
#view-all-text{
  font-size:2vw;
  width:20vw;
  top:2vw;
}

.powered-by-sponsors-container a{
width:14vw;
height:14vw;
margin:.5vw;
border-radius: 1vw;
}

.powered-by-view-all-text{
  font-size:2vw;
  padding:1vw;
}
}

@media only screen and (max-width: 1200px) {
  #powered-by-container {
    bottom: 3vh;
    left: 5vw;
    width: 35rem;
    height: 20rem; /* Increased height to accommodate larger icons */
  }
  .powered-by-title {
    font-size: 1.8rem; /* Slightly larger title */
    padding-top: 0.5rem;
    padding-bottom: 1rem; /* Add padding to maintain spacing with icons */
    margin-bottom: 0.5rem; /* Control gap between title and icons */
    height: auto; /* Let height be determined by content */
  }
  .powered-by-sponsors-container a {
    width: 5.5rem; /* Increased from 4rem */
    height: 5.5rem; /* Increased from 4rem */
    margin: 0.5rem;
  }
}

@media only screen and (max-width: 576px) {
  #powered-by-container {
    position: absolute;
    top: 45vh;
    left: 4vw;
    width: 90vw;
    height: auto;
    padding: 1rem;
  }
  .powered-by-title {
    font-size: 1.5rem; /* Increased from 1.2rem */
    margin-bottom: 0rem; /* Ensure consistent spacing to icons */
    padding-top: 0.3rem; /* Reduced top padding to save space */
    height: auto;
    width: auto;
  }
  .powered-by-sponsors-container a {
    width: 3.5rem; /* Increased from 3rem */
    height: 3.5rem; /* Increased from 3rem */
    margin: 0.4rem;
  }
  .powered-by-sponsor-image {
    border-radius: 10px; /* Smaller radius for smaller icons */
  }
}