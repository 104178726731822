.position-posting-container {
    padding: 30px 0 100px 0;
    min-height: calc(100vh - 770px);
    width: 900px;
    margin: auto;
    overflow: hidden;

    /* border:1px solid red; */
}

.position-posting-name {
    padding: 30px 20px 10px 0px;
    width: 490px;
    margin-left: 0px;
    float: left;
    text-align: left;
    color: #fff;
    /* border:1px solid red; */
}

.position-back-button {
    color: rgb(100, 100, 100);
    z-index: 1000000;
    font-size: 20px;
}

.positions-back-button-container {
    width: 100%;
    /* border: 1px solid red; */
    margin: 10px 0;
}

.position-back-button:hover {
    cursor: pointer;
    color: white;

}

.position-posting-applynow {
    height: 50px;
    width: 150px;
    padding: 10px;
    font-size: 20px;
    margin-top: 40px;
    position: relative;
    top: 30px;
    /* left: 100px; */
    border: 1px solid rgb(73, 95, 109);
    color: rgb(93, 120, 138);
    text-decoration: none;
}

.position-posting-applynow:hover {
    border: 1px solid rgb(60, 123, 165);
    color: rgb(60, 123, 165);
    background: rgb(197, 197, 197);
    cursor: pointer;
    box-shadow: 3px 3px 5px #ccc;
    transition: .3s ease-in-out;
}

.position-posting-applynowi-disabled {
    height: auto;
    padding: 15px;
    font-size: 20px;
    margin-top: 50px;
    position: relative;
    top: 40px;
    left: -40px;
    border: 1px solid rgb(73, 95, 109);
    color: rgb(93, 120, 138);
    text-decoration: none;
    cursor: not-allowed;
}

.position-posting-description {
    width: 900px;
    margin: auto;
    padding-top: 20px;
    text-align: left;
    font-size: 20px;
    color: #fff;
    white-space: pre-wrap;
}

.position-posting-description-bold {
    font-weight: bold;
    padding-bottom: 10px;
}

.position-posting-date-opened {
    width: 100%;
    float: left;
    padding-left: 0px;
    margin-bottom: 20px;
    font-size: 18px;
    text-align: left;
    color: #888;
    /* border:1px solid purple; */
}

.position-posting-date-closed {
    width: 100%;
    float: left;
    padding-left: 0px;
    margin-bottom: 20px;
    font-size: 18px;
    text-align: left;
    color: #888;
    /* border:1px solid purple; */
}

.position-posting-heading {
    width: 900px;
    margin: auto;
    text-align: left;
    margin-top: 80px;
    /* border:1px solid green; */
    font-size: 25px;
    color: #ccc;
}


.position-posting-list {
    width: 870px;
    margin: auto;
    color: #fff;
    /* border:1px solid lightblue; */
}

.position-poosting-item {
    margin-top: 10px;
    text-align: left;
    font-size: 18px;
    /* border:1px solid rebeccapurple; */
}

.application-container {
    padding: 0px;
    height: auto;
    min-height: calc(100vh - 770px);
    /* border:1px solid red; */
    /* height:800px; */
}

.back-to-position-container {
    height: 0px;
    width: 100%;
    /* padding-bottom: 20px; */
    margin: auto
}

.back-to-position-button {
    height: 0px;
    width: 290px;
    float: right;
    /* padding:15px; */
    font-size: 20px;
    margin-top: 0px;
    position: relative;
    top: 45px;
    left: 00px;
    color: rgb(24, 24, 24);
    text-decoration: none;
}

.survey-container {
    /* border:1px solid blue; */
    height: auto;
    overflow: hidden;
}

@media only screen and (max-width: 1300px) {

    .back-to-position-button {
        /* left:1000px; */
        z-index: -10;
        opacity: 0;
    }
}

.back-to-position-button:hover {
    color: rgb(124, 150, 167);
    cursor: pointer;
}

.application {
    /* width:650px; */
    /* border:1px solid red; */
}


@media only screen and (max-width: 800px) {

    .position-posting-container {
        padding: 20px 0 30px 0;
        min-height: calc(100vh - 770px);
        position: relative;
        overflow: hidden;
        width: 100%;
    }

    .position-back-button {
        color: rgb(132, 172, 218);
        z-index: 1000000;
        font-size: 12px;
        padding-left: 17px;
    }

    .position-posting-name {
        padding: 0px 10px 10px 10px;
        width: 100%;
        margin-left: 5px;
        float: left;
        text-align: left;
        font-size: 20px;
        /* border:1px solid red; */
    }

    .position-posting-applynow-container {
        position: relative;
        /* padding-top: 40px; */
        padding-left: 17px;
        width: 280px;
        /* bordeR:1px solid blue; */
    }

    .position-posting-applynow {
        height: auto;
        padding: 7px;
        font-size: 12px;
        margin-top: 10px;
        position: relative;
        top: -3px;
        /* left: -100px; */
        border: 1px solid rgb(73, 95, 109);
        color: rgb(93, 120, 138);
        text-decoration: none;
    }

    .position-posting-applynow:hover {
        border: 1px solid rgb(60, 123, 165);
        color: rgb(60, 123, 165);
        background: rgb(247, 251, 255);

        box-shadow: 3px 3px 5px #ccc;
    }

    .position-posting-applynowi-disabled {
        height: auto;
        padding: 7px;
        font-size: 11px;
        margin-top: 30px;
        position: relative;
        top: 0px;
        left: -115px;
        border: 1px solid rgb(73, 95, 109);
        color: rgb(93, 120, 138);
        text-decoration: none;
        cursor: not-allowed;
    }

    .position-posting-description {
        width: 90%;
        margin: auto;
        padding-top: 10px;
        text-align: left;
        font-size: 12px;
        /* color: #000; */
    }

    .position-posting-description-bold {
        font-weight: normal;
    }

    .position-posting-date {
        width: 100%;
        float: left;
        padding-left: 15px;
        margin-bottom: 0px;
        font-size: 10px;
        padding-top: 15px;
        padding-bottom: 30px;
        text-align: left;
        color: #888;
        /* border:1px solid purple; */
    }

    .position-posting-heading {
        width: 90%;
        margin: auto;
        text-align: left;
        margin-top: 30px;
        /* border:1px solid green; */
        font-size: 15px;
        color: #666;
    }


    .position-posting-list {
        width: 85%;
        margin: auto;

        /* border:1px solid lightblue; */
    }

    .position-poosting-item {
        margin-top: 7px;
        text-align: left;
        font-size: 12px;
        /* border:1px solid rebeccapurple; */
    }

    .application-container {
        padding: 0px;
        /* height:800px   ; */
        width: 100%;
        /* height:800px; */
    }


}