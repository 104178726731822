#photoslide-container{
  width:1400px;
  height:100vh;
  margin:0 auto;
  padding:40px 0;
  /* border:2px solid blue; */
  position:relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  opacity:0;
  transition: .5s ease-in-out;
}

  .white-line{
    position: relative;
    /* border:1px solid green; */
    width:1300px;
    height:40px;
  }
  .white-line div{
    background: #aaa;
    width:2px;
    height:100%;
    margin: 0 auto
  }

  #description-container{
    position: relative;
    width: 600px;
    height:500px;
    top:0px;
    /* top:0; */
    transition: .6s;
    /* border: 1px solid blue; */
  }

    #photoslide-title{
      color:#eee;
      width:100%;
      font-size:55px;
      text-align: left;
      padding:70px 0 0 0;

    }

    #photoslide-description{
      color:#eee;
      font-size:22px;
      line-height: 1.6em;
      padding:20px 0;
      text-align:justify;
    }

  .photoslide-image-container{
    position: relative;
    width:680px;
    height:500px;
    margin-left:100px;
    /* border:1px solid red; */
    overflow: hidden;
    /* align-content: center; */
  }

    .photoslide-image{
      width:100%;
    }

  @media only screen and (max-width: 1500px) {
    #photoslide-container{
      /* border: 1px solid beige; */
      padding:4vw 0;
      width:100vw;
      height:90vw;
    }
    
      .white-line{
        height:20px;
        /* border: 1px solid beige; */
      }
      .white-line div{
        height:20px
      }
    
      #description-container{
        height:40vw;
        width:45vw;
        /* border: 1px solid beige; */
      }
    
        #photoslide-title{
          font-size: 5vw;
          padding:3vw 0 0 0;
        }
    
        #photoslide-description{
          font-size: 1.5vw;
        }
    
      .photoslide-image-container{
        width:48vw;
        height:40vw;
        margin-left:2vw;
      }
    
        .photoslide-image{
        }
    
  }

  @media only screen and (max-width: 500px) {
    #photoslide-container{
      /* border: 1px solid beige; */
      padding:4vw 0;
      width:100vw;
      height:60vh;
    }
    
      .white-line{
        height:20px;
        /* border: 1px solid beige; */
      }
      .white-line div{
        height:20px
      }
    
      #description-container{
        height:30vw;
        width:48vw;
        padding-top:1vw;
        /* border: 1px solid beige; */
      }
    
        #photoslide-title{
          font-size: 5.2vw;
          padding:1vw 0 0 0;
        }
    
        #photoslide-description{
          font-size: 2.5vw;
        }
    
      .photoslide-image-container{
        width:48vw;
        height:40vw;
        margin-left:2vw;
      }
    
        .photoslide-image{
        }
  }