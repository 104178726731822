.sponsor-explorer-container {
  width:100%;
  height: auto;
  background: #000000;
  padding-top:50px;
  opacity:1;
}

.sponsor-container {
  padding: 50px 0;
  max-width: 1500px; 
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start; 
  justify-content: center;
  box-sizing: border-box;
  /* border:1px solid #222; */
}

.sponsor{
  width: 30%; 
  height: auto; 
  position: relative;
  padding: 10px;
  margin: 20px;
  display: flex;
  flex-direction: column; 
  align-items: center; 
  transition: 1s ease-in-out;
  box-sizing: border-box; 
  text-decoration: none;
}

.sponsors-logo {
  width: 100%;
  max-width: 200px; 
  height: auto; 
  display: block; 
  margin: 0 auto;
  padding: 20px; 
  background-color: transparent; /* Transparent background */
  object-fit: contain; 
  object-position: center; 
  border-radius: 0px; 
  filter: grayscale(100%) brightness(3) contrast(0.1); /* Pure white effect (255,255,255) */
  opacity: 1; /* Full opacity for true white */
}

/* Exempt Official EV Partners from the whitening filter */
.sponsor-container.partner .sponsors-logo {
  filter: none; /* Remove filter for partner logos */
  background-color: white; /* Keep white background for partner logos */
}

.sponsors-text-container{
  width:70%;
  padding:0px 10px;
  margin-left: 5px;
  text-align: center;
  box-sizing: border-box;
}
.sponsors-text-container h1{
  font-size: 27px;
  margin:10px 0;
  padding-bottom: 5px;
  font-weight: 300;
  border-bottom: 1px solid rgb(202, 202, 202);
}
.sponsors-text-container h2{
  font-size: 14px;
  text-align: justify;
  color:#ffffff;
  line-height: 1.4em;
  font-weight: 200;
  }

.sponsor-flag-pole{
  width:0;
  height:3px;
  position:absolute;
  bottom:0;
  right:0;
  background:rgb(51, 102, 170);
  opacity:0;
  transition:.5s ease-in-out;
}
.sponsor:hover .sponsor-flag-pole{
  width:100%;
  opacity:1;
}
.sponsor-flag{
  position: absolute;
  bottom:0;
  right:0;
  height:40px;
  width:0px;
  background-color: rgb(51, 102, 170);
  overflow: hidden;
  opacity: 0;
  transition:.5s ease-in-out;
}
.sponsor-flag-text{
  color:rgb(74, 118, 175);
  text-align: center;
  font-size: 25px;
  font-weight: 100;
  margin-top: 5px;
  transition: .7s ease-in-out;
}
.sponsor:hover .sponsor-flag{
  width:100px;
  opacity: 1;
}.sponsor:hover .sponsor-flag-text{
   color:#fff;
}

h1 {
  color: rgb(255, 255, 255);
}

.sponsor-type-heading{
  padding-top:30px;
  padding-bottom: 30px;
  font-size: 30px;
  /* border:1px solid red; */
  text-align: center;
  color: rgb(255, 255, 255);
}

.sponsor-type-heading span {
  padding:7px;
  font-family: 'Rajdhani', sans-serif;
}

.top-sponsor-container {
  width: 90%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin: auto; /* Optional padding around the container */
}

.reworked-sponsor-container{
  width: 100%;
  margin: 0% 50px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); 
  gap: 16px; 
  padding: 16px; 
}

.reworked-sponsor {
  display: flex;
  height: 10vw;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000; 
  padding: 10px; 
  background-color: #000000; 
  overflow: hidden; 
  transition: transform 0.3s ease; 
}

.reworked-sponsor:hover {
  transform: scale(1.05); 
}

/* Default white filter for most sponsor logos */
.reworked-sponsors-logo {
  width: 100%; 
  height: auto; 
  max-height: 100px; 
  object-fit: contain;
  filter: grayscale(100%) brightness(10) contrast(1); /* Pure white effect (255,255,255) */
  opacity: 1; /* Full opacity for true white */
}

/* Custom brightness specifically for Gene Haas Foundation logo */
.reworked-sponsor[data-sponsor-id="genehaasfoundation"] .reworked-sponsors-logo {
  filter: grayscale(100%) brightness(1) contrast(1);
}

.reworked-sponsor[data-sponsor-id="itech"] .reworked-sponsors-logo {
  filter: grayscale(100%) brightness(2) contrast(1);
}

.reworked-sponsor[data-sponsor-id="petro"] .reworked-sponsors-logo {
  filter: grayscale(100%) brightness(1) contrast(1);
}

.reworked-sponsor[data-sponsor-id="dana"] .reworked-sponsors-logo {
  filter: grayscale(100%) brightness(10) contrast(0.5);
}

@media only screen and (max-width: 1500px) {

  .reworked-sponsor-container {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); 
  }

  .sponsor-explorer-container {
    padding-top:10vw;
  }
  
  .sponsor-container {
    padding:5vw 0;
    width:100%;
  }
  
  .sponsor{
    width:40vw;
    height:22rem;
    padding:0px 0 0 1vw;
    margin:2vw 4vw;
  }
  
  .sponsors-text-container{
    padding:0px .5vw;
    margin-left: .4vw;
  }
  .sponsors-text-container h1{
    font-size: 2.2vw;
    margin:.8vw 0;
    padding-bottom: .4vw;
  }
  .sponsors-text-container h2{
    font-size: 1.1vw;
    }
  
  .sponsor-flag-pole{
    height:1;
    position:absolute;
  }

  .sponsor-flag{
    height:3vw;
  }
  .sponsor-flag-text{
    font-size: 2vw;
    margin-top: .4vw;
  }
  .sponsor:hover .sponsor-flag{
    width:8vw;
  }
  
  .sponsor-type-heading{
    padding-top:2vw;
    padding-bottom: 2vw;
    font-size: 3.5vw;
  }
  
  .sponsor-type-heading span {
    padding:.4vw;
  }

}

@media only screen and (max-width: 768px) {
  /* Make sponsor logos bigger on tablets */
  .reworked-sponsor {
    height: 15vw; /* Increased from 10vw */
    padding: 15px; /* More padding for better spacing */
  }
  
  .reworked-sponsors-logo {
    max-height: 120px; /* Increased from 100px */
  }
  
  .reworked-sponsor-container {
    gap: 20px; /* Increased gap for better separation */
  }
}

@media only screen and (max-width: 600px) {
  /* Make sponsor logos even bigger on mobile phones */
  .sponsor {
    width: 70vw;
    height: 22rem;
  }
  
  .reworked-sponsor {
    height: 20vw; /* Significantly taller on mobile */
    padding: 20px 15px; /* More vertical padding */
  }
  
  .reworked-sponsors-logo {
    max-height: 140px; /* Much larger logo height */
  }
  
  .reworked-sponsor-container {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr)); /* Larger minimum column width */
    gap: 25px; /* Even more gap on mobile */
  }
}

@media only screen and (max-width: 480px) {
  /* Additional adjustments for very small screens */
  .reworked-sponsor {
    height: 25vw; /* Even taller on very small screens */
  }
  
  .reworked-sponsor-container {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* Adjusted for very small screens */
  }
}

@media only screen and (max-width: 600px) {
  .sponsor {
    width: 70vw;
    height:22rem;
  }
  .sponsors-text-container{
    padding:0px .5vw;
    margin-left: .4vw;
  }
  .sponsors-text-container h1{
    font-size: 3.2vw;
    margin:.8vw 0;
    padding-bottom: .4vw;
  }
  .sponsors-text-container h2{
    font-size: 2.5vw;
    }

    .sponsor-type-heading{
      font-size: 4.5vw;
    }
}