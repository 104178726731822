.countdown-section {
  width: 100%;
  background-color: #9b1c22;  /* Dark red background */
  padding: 50px 0; 
  font-family: 'Inter', sans-serif;
}

.countdown-container {
  max-width: 1400px; 
  margin: 0 auto;
  text-align: center;
  padding: 30px; 
}

.countdown-title {
  font-size: 4.5rem; 
  font-weight: 600;
  color: white;
  margin-bottom: 20px; 
  text-align: center;
  position: relative;
}

/* Remove the ::after pseudo-element and create a separate class for the underline */
.countdown-title-underline {
  display: flex;
  justify-content: center;
  margin: 15px 0 50px; /* Adjusted margin */
}

.black-line {
  width: 80%; 
  height: 6px; 
  background-color: #000; 
  border-radius: 3px;
  margin-bottom: 2rem;
}

/* Remove the brush-stroke-img class as it's no longer needed */

.countdown-timer {
  display: flex;
  justify-content: center;
  align-items: flex-start; 
  gap: 10px; 
  margin-top: 30px; 
}

/* New class to contain box and label */
.countdown-unit {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-left: 25px; 
}

.countdown-box {
  background-color: white;
  border-radius: 20px;  
  width: 200px; 
  height: 200px; 
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 15px; 
  z-index: 2; 
  box-shadow: none; /* Remove any default box shadow */
}

/* Create the black box shadow effect */
.countdown-box-shadow {
  content: '';
  position: absolute;
  width: 200px; 
  height: 200px; 
  background-color: black;
  border-radius: 20px;  /* Slightly smaller than parent */ 
  transform: translateX(-10%) translateY(-10%);
  z-index: 1; /* Make sure this is lower than the white box but still positive */
  top: 0;
  left: 0;
}

.countdown-value {
  font-size: 6.5rem; 
  font-weight: 700;
  color: black;
  line-height: 1;
}

.countdown-label {
  color: white; /* Changed from black to white since it's now on the red background */
  font-size: 1.8rem; 
  margin-top: 15px; 
}

.countdown-separator {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 50px; 
  height: 120px;
  padding: 0 15px;
}

/* Replace pseudo-elements with actual dot elements */
.countdown-dot {
  width: 40px; 
  height: 40px; 
  background-color: black;
  border-radius: 100%; 
  margin: 8px; 
}

/* Responsive styles */
@media only screen and (max-width: 1200px) {
  .countdown-section {
    padding: 40px 0;
  }
  
  .countdown-container {
    max-width: 1100px;
    padding: 25px;
  }
  
  .countdown-title {
    font-size: 4rem;
  }
  
  .black-line {
    width: 80%;
  }
  
  .countdown-timer {
    gap: 8px;
  }
  
  .countdown-unit {
    margin-left: 15px;
  }
  
  .countdown-box, .countdown-box-shadow {
    width: 170px;
    height: 170px;
  }
  
  .countdown-value {
    font-size: 5.5rem;
  }
  
  .countdown-label {
    font-size: 1.6rem;
  }
  
  .countdown-separator {
    margin-top: 45px;
    height: 100px;
  }
  
  .countdown-dot {
    width: 35px;
    height: 35px;
  }
}

@media only screen and (max-width: 992px) {
  .countdown-section {
    padding: 35px 0;
  }
  
  .countdown-container {
    padding: 20px;
  }
  
  .countdown-title {
    font-size: 3.2rem; /* Reduced from 3.8rem */
  }
  
  .black-line {
    width: 80%px;
    height: 5px;
  }
  
  .countdown-timer {
    gap: 5px;
  }
  
  .countdown-box, .countdown-box-shadow {
    width: 130px; /* Reduced from 160px */
    height: 130px; /* Reduced from 160px */
  }
  
  .countdown-value {
    font-size: 4rem; /* Reduced from 5rem */
  }
  
  .countdown-label {
    font-size: 1.2rem; /* Reduced from 1.5rem */
    margin-top: 10px;
  }
  
  .countdown-separator {
    margin-top: 38px; /* Adjust to center with smaller boxes */
    height: 60px; /* Reduced from unspecified */
  }
  
  .countdown-dot {
    width: 18px; /* Reduced from 30px */
    height: 18px; /* Reduced from 30px */
    margin: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .countdown-section {
    padding: 30px 0; /* Reduced from 40px */
  }
  
  .countdown-container {
    padding: 15px 10px; /* Reduced from 20px 15px */
  }
  
  .countdown-title {
    font-size: 2.6rem; /* Reduced from 3.2rem */
    margin-bottom: 15px; /* Added smaller margin */
  }
  
  .black-line {
    width: 80%;
    height: 4px;
  }
  
  .countdown-timer {
    gap: 3px; /* Reduced from 5px */
  }
  
  .countdown-box, .countdown-box-shadow {
    width: 95px; /* Reduced from 130px */
    height: 95px; /* Reduced from 130px */
  }
  
  .countdown-value {
    font-size: 3.2rem; /* Reduced from 4.5rem */
  }
  
  .countdown-label {
    font-size: 1rem; /* Reduced from 1.3rem */
    margin-top: 6px; /* Reduced from 10px */
  }
  
  .countdown-separator {
    margin-top: 30px; /* Adjusted for smaller boxes */
    height: 40px; /* Reduced from 60px */
  }
  
  .countdown-dot {
    width: 14px; /* Reduced from 20px */
    height: 14px; /* Reduced from 20px */
    margin: 3px;
  }
}

/* Improve small screen responsiveness - keep horizontal layout */
@media only screen and (max-width: 576px) {
  .countdown-section {
    padding: 30px 0;
  }
  
  .countdown-title {
    font-size: 2.5rem;
  }
  
  .black-line {
    width: 80%;
    height: 4px;
    margin-bottom: 0;
  }
  
  .countdown-timer {
    /* Remove flex-wrap to keep horizontal layout */
    flex-wrap: nowrap;
    gap: 2px; /* Reduce gap to fit items */
  }
  
  .countdown-unit {
    margin: 0 5px; /* Reduce margin to fit items */
  }
  
  /* Keep separators visible */
  .countdown-separator {
    display: flex; /* Keep visible */
    margin-top: 20px; /* Adjust position */
    height: 40px;
  }
  
  .countdown-dot {
    width: 10px; /* Smaller dots */
    height: 10px;
    margin: 2px;
  }
  
  .countdown-box, .countdown-box-shadow {
    width: 65px; /* Smaller boxes */
    height: 65px;
  }
  
  .countdown-value {
    font-size: 2.2rem; /* Smaller text */
  }
  
  .countdown-label {
    font-size: 0.7rem;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 480px) {
  /* Apply the compact layout from 380px */
  .countdown-timer {
    flex-wrap: wrap; /* Now wrap for smaller screens */
    justify-content: center;
    gap: 2px;
  }
  
  .countdown-unit {
    margin: 5px 10px;
  }
  
  /* Hide separators on smaller screens */
  .countdown-separator {
    display: none;
  }
  
  .countdown-box, .countdown-box-shadow {
    width: 65px; /* Smaller boxes */
    height: 65px;
  }
  
  .countdown-value {
    font-size: 2.2rem;
  }
  
  .countdown-label {
    font-size: 0.8rem;
    margin-top: 5px;
  }
  
  .black-line {
    width: 80%;
    height: 4px;
  }
  
  .brush-stroke-img {
    width: 180px;
  }
}

/* Remove the 380px media query or keep it only for extremely small devices */
@media only screen and (max-width: 380px) {
  .countdown-unit {
    margin: 4px 8px; /* Even tighter spacing */
  }
  
  .countdown-box, .countdown-box-shadow {
    width: 60px; /* Even smaller */
    height: 60px;
  }
  
  .countdown-value {
    font-size: 2rem;
  }
}