.footer-container {
  background-color: #000;
  padding: 60px 40px;
  color: #fff;
  font-family: 'Lato', sans-serif;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: 60px;
}

.footer-section {
  flex: 1;
}

.footer-logo {
  width: 120px;
  margin-bottom: 30px;
}

.footer-section h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #fff;
}

.company-description p {
  font-size: 16px;
  line-height: 1.6;
  color: #fff;
  font-weight: 400;
  max-width: 600px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 15px;
}

.footer-section ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
}

.social-icons {
  display: flex;
  gap: 25px;
}

.social-icon {
  font-size: 32px;
  color: #8B0000;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #660000;
}

/* Remove the old image-based icon styles */
/* .social-icon.instagram,
.social-icon.linkedin,
.social-icon.facebook,
.social-icon.youtube {
  background-image: none;
} */

/* Mobile Responsiveness */
@media only screen and (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .footer-section {
    text-align: center;
  }

  .social-icons {
    justify-content: center;
  }
}