.introduction-section {
    width: 100%;
    background-color: black;
    padding: 0; /* Remove padding to allow full-height image */
    position: relative;
    min-height: 50vh; /* Ensure minimum height */
}

.introduction-container {
    max-width: 100%; /* Allow container to be full width */
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: stretch; /* Change to stretch so children take full height */
    padding: 0;
    height: 100%; /* Make container fill section height */
    overflow: hidden;
}

.introduction-content {
    flex: 0 0 50%; /* Fixed width for content side (50% of container) */
    padding: 0 60px 0 100px; /* Increased padding for better spacing */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; /* Center content vertically */
    min-height: 700px; /* Ensure minimum height */
}

.introduction-title {
    font-size: 4rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: white;
    font-family: 'Inter', sans-serif;
    position: relative;
    width: 450px;
    text-align: center;
}

.introduction-title::after {
    content: '';
    display: block;
    width: 450px;
    height: 4px;
    background-color: #911a1e;
    margin-top: 15px;
    margin-bottom: 30px;
}

.introduction-text {
    font-size: 1.7rem;
    line-height: 1.2;
    margin-bottom: 40px;
    color: white;
    font-family: 'Inter', sans-serif;
    max-width: 90%;
}

.learn-more-container {
    display: flex;
    justify-content: center;
    width: 80%;
}

.learn-more-button {
    background-color: transparent;
    color: white;
    padding: 18px 50px;  /* Increased padding */
    font-size: 1.7rem;   /* Increased font size */
    font-weight: 500;
    border: 2px solid #911a1e;
    border-radius: 0;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.3s;
    font-family: 'Inter', sans-serif;
    display: inline-block;
    align-self: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.learn-more-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #911a1e;
    transition: left 0.4s ease-in-out;
    z-index: -1;
}

.learn-more-button:hover {
    color: #fff; /* Keep text white */
}

.learn-more-button:hover::before {
    left: 0;
}

/* Remove the previous hover effect */
.learn-more-button:hover {
    background-color: transparent;
    transform: none;
}

.introduction-image-container {
    flex: 0 0 50%; /* Fixed width for image side (50% of container) */
    position: relative;
    overflow: hidden;
    min-height: 700px; /* Match minimum section height */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.introduction-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-10%) scale(1.1);
    object-fit: cover; /* Cover the container without stretching */
    object-position: center; /* Center the image */
    border: none; /* Remove border for cleaner look */
}

/* Responsive styles */
@media only screen and (max-width: 1200px) {
    .introduction-content {
        padding: 0 40px 0 60px; /* Adjust padding for medium-large screens */
    }
    .introduction-image {
        transform: translateX(-5%) scale(1.1); /* Adjust transform for medium-large screens */
    }
}

@media only screen and (max-width: 992px) {
    .introduction-container {
        flex-direction: column;
    }

    .introduction-content {
        flex: 0 0 auto;
        padding: 80px 40px; /* Add vertical padding */
        margin-bottom: 0;
        align-items: center;
        text-align: center;
        min-height: auto;
    }
    
    .introduction-title::after {
        margin-left: auto;
        margin-right: auto;
    }

    .learn-more-button {
        align-self: center; /* Center the button on smaller screens */
    }

    .introduction-image-container {
        flex: 0 0 auto;
        height: 600px; /* Make image bigger on tablets */
        position: relative;
        overflow: hidden;
    }

    .introduction-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: none; /* Remove the transform on mobile */
        object-fit: cover;
        object-position: center;
    }
}

@media only screen and (max-width: 768px) {
    .introduction-section {
        padding: 70px 0;
    }

    .introduction-title {
        font-size: 3.2rem;
    }
    
    .introduction-title::after {
        width: 200px;
    }

    .introduction-text {
        font-size: 1.15rem;
    }

    .learn-more-button {
        padding: 16px 40px;
        font-size: 1.2rem;
    }

    .introduction-image-container {
        height: 400px; /* Smaller height for medium screens */
        overflow: hidden;
    }

    .introduction-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: none; /* Ensure transform is removed */

    }
}

@media only screen and (max-width: 480px) {
    .introduction-section {
        padding: 50px 0;
    }

    .introduction-title {
        font-size: 2.5rem;
    }
    
    .introduction-title::after {
        width: 150px;
    }

    .introduction-text {
        font-size: 1rem;
    }

    .learn-more-button {
        padding: 14px 35px;
        font-size: 1.1rem;
    }
    .introduction-image-container {
        height: 300px;
    }
    .introduction-image {
        height: 80%;
        object-position: 60% center; /* Move image to the left */
    }
}
