.posts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 80px;
    position: relative; 
}

.instagram-account {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    color: rgb(206, 205, 205);
    font-size: 1rem;
    position: absolute;
    text-align: center;
    white-space: nowrap;
    overflow: visible;
    text-decoration: none;
}

.instagram-posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 6rem;
    gap: 5rem;
    margin-bottom: 8rem;
}

.instagram-posts img {
    width: 25rem;
    height: auto;
}

/* Responsive styles for max-width 1280px */
@media only screen and (max-width: 1280px) {
    .instagram-account {
        font-size: 0.9rem;
    }
    
    .instagram-posts {
        gap: 4rem;
    }

    .instagram-posts img {
        width: 22rem;
    }
}

/* Responsive styles for max-width 1024px */
@media only screen and (max-width: 1024px) {
    .instagram-account {
        font-size: 0.8rem;
    }

    .instagram-posts img {
        width: 25rem;
    }
}

/* Responsive styles for max-width 600px */
@media only screen and (max-width: 600px) {
    .instagram-account {
        font-size: 3vw;
    }

    .instagram-posts {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }

    .instagram-posts img {
        width: 100%;
        max-width: 25rem;
    }
}