.formula-info-section {
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 0; /* Remove padding to allow full-width image */
  font-family: 'Inter', sans-serif;
  min-height: 700px; /* Match introduction minimum height */
}

.formula-info-container {
  max-width: 100%; /* Full width container */
  margin: 0;
  padding: 0;
}

/* Main content layout */
.formula-info-main {
  display: flex;
  align-items: stretch; /* Match introduction stretch */
  gap: 0; /* Remove gap */
  margin-bottom: 0; /* Remove margin */
}

/* Image styling */
.formula-info-image-container {
  flex: 0 0 50%; /* Changed from 45% to exactly 50% */
  height: auto; /* Auto height */
  min-height: 700px; /* Minimum height */
  overflow: hidden;
  position: relative; /* For absolute positioning of the image */
}

.formula-info-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute; /* Match introduction absolute positioning */
  top: 30px; /* Added top padding to the image */
  left: 0;
}

/* Text content styling - match introduction */
.formula-info-content {
  flex: 0 0 50%; /* Changed from 55% to exactly 50% */
  padding: 0 80px 0 120px; /* Increased padding on both sides */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; /* Center content vertically */
  min-height: 700px; /* Ensure minimum height */
}

.formula-info-title {
  font-size: 4rem; /* Match introduction size */
  font-weight: 600;
  margin-bottom: 20px;
  position: relative;
  color: white;
  width: 90%;
  text-align: center;
}

/* Red underline for title */
.formula-info-title::after {
  content: '';
  display: block;
  width: 100%;
  height: 4px;
  background-color: #911a1e;
  margin-top: 15px;
  margin-bottom: 30px; /* Add spacing after line */
}

.formula-info-text {
  text-align: center;
  font-size: 1.7rem; /* Match introduction size */
  line-height: 1.2; /* Match introduction line height */
  margin-bottom: 40px;
  max-width: 90%; /* Match introduction max-width */
}

/* Add container for button to center it with paragraph */
.formula-info-button-container {
  display: flex;
  width: 90%; /* Match paragraph width */
  justify-content: center; /* Center the button */
  margin-top: 10px;
}

.formula-info-button {
  display: inline-block;
  padding: 18px 50px; /* Match introduction button size */
  font-size: 1.7rem; /* Match introduction button font */
  font-weight: 500;
  color: #fff;
  background-color: transparent;
  border: 2px solid #911a1e;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.formula-info-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #911a1e;
  transition: left 0.4s ease-in-out;
  z-index: -1;
}

.formula-info-button:hover {
  color: #fff; /* Keep text white */
}

.formula-info-button:hover::before {
  left: 0;
}

/* Remove the previous hover effect */
.formula-info-button:hover {
  background-color: transparent;
  transform: none;
}

/* Stats section styling */
.formula-stats-container {
  display: flex;
  justify-content: space-between;
  padding: 80px 40px; /* Increased padding */
  background-color: #090909; /* Slightly lighter background */
}

.formula-stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 30%;
}

/* New container for icon and value horizontally */
.formula-stat-top {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.formula-stat-icon {
  width: 70px; /* Adjusted icon size */
  height: 70px; /* Adjusted icon size */
  margin-right: 15px; /* Add space between icon and number */
  object-fit: contain;
}

.formula-stat-value {
  font-size: 4.5rem; /* Adjusted for horizontal layout */
  font-weight: 700;
  color: #911a1e; /* Add color for emphasis */
  line-height: 1;
}

.formula-stat-label {
  font-size: 1.8rem; /* Increased font size */
  font-weight: 400;
}

/* Responsive styles */
@media only screen and (max-width: 1200px) {
  .formula-info-content {
    padding: 0 60px 0 80px; /* Adjusted padding for medium-large screens */
  }

  .formula-stat-value {
    font-size: 3.8rem;
  }
  
  .formula-stat-icon {
    width: 60px;
    height: 60px;
  }
}

/* Responsive styles - updated breakpoint and image positioning */
@media only screen and (max-width: 1000px) {
  .formula-info-section {
    padding: 0;
  }

  .formula-info-main {
    flex-direction: column;
  }
  
  .formula-info-image-container {
    flex: 0 0 auto;
    width: 100%;
    height: 500px; /* Slightly reduced height */
    min-height: auto;
    order: 2; /* Ensure image appears after text */
  }
  
  .formula-info-content {
    flex: 0 0 auto;
    width: 100%;
    padding: 60px 40px; /* Adjusted padding */
    align-items: center;
    text-align: center;
    min-height: auto;
    order: 1; /* Ensure text appears before image */
  }
  
  .formula-info-title {
    font-size: 3.5rem;
  }
  
  .formula-info-title::after {
    margin-left: auto;
    margin-right: auto;
  }
  
  .formula-info-button-container {
    width: 100%;
  }
  
  /* Stats section adjustments */
  .formula-stats-container {
    padding: 60px 30px;
  }
  
  .formula-stat-value {
    font-size: 3.5rem;
  }
  
  .formula-stat-icon {
    width: 50px;
    height: 50px;
  }
  
  .formula-stat-label {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 992px) {
  .formula-info-section {
    padding: 0;
  }

  .formula-info-main {
    flex-direction: column;
  }
  
  .formula-info-image-container {
    flex: 0 0 auto;
    width: 100%;
    height: 600px; /* Match introduction responsive height */
    min-height: auto;
  }
  
  .formula-info-content {
    flex: 0 0 auto;
    width: 100%;
    padding: 80px 60px; /* Increased horizontal padding */
    align-items: center;
    text-align: center;
    min-height: auto;
  }
  
  .formula-info-title::after {
    margin-left: auto;
    margin-right: auto;
  }
  
  .formula-info-button-container {
    width: 100%;
  }
  
  .formula-stat-value {
    font-size: 3.5rem;
  }
  
  .formula-stat-icon {
    width: 50px;
    height: 50px;
  }
  
  .formula-stat-label {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .formula-stats-container {
    flex-direction: column;
    gap: 50px; /* Increased gap for better separation */
    padding: 60px 20px;
  }
  
  .formula-stat-item {
    width: 100%;
  }
  
  .formula-info-title {
    font-size: 3.2rem; /* Match introduction responsive size */
  }
  
  .formula-info-text {
    font-size: 1.15rem; /* Match introduction responsive size */
  }
  
  .formula-info-button {
    padding: 16px 40px; /* Match introduction responsive button */
    font-size: 1.2rem; /* Match introduction responsive button */
  }
  
  .formula-stat-top {
    margin-bottom: 10px;
  }
  
  .formula-stat-icon {
    width: 45px;
    height: 45px;
    margin-right: 10px;
  }
  
  .formula-stat-value {
    font-size: 3rem;
  }
  
  .formula-stat-label {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 480px) {
  .formula-stat-icon {
    width: 40px;
    height: 40px;
  }
  
  .formula-stat-value {
    font-size: 2.5rem;
  }
  
  .formula-stat-label {
    font-size: 1rem;
  }
}