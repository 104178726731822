.hero-explorer-container {
  padding-top: 100px;
  text-align: center;
}

.hero-type-heading {
  width: 100%;
  font-size: 3vw;
  margin: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.hero-type-text {
  color: white;
  font-size: 1.5vw;
  margin-top: 40px;
  padding: 0 10%;
  line-height: 1.5;
}

.hero-button-container {
  display: flex;
  justify-content: center; 
  margin-top: 20px;
}

.divider {
  width: 90%;
  margin: 40px auto; 
  height: .5;
  border-color :rgb(80,81,82);
}

@media only screen and (max-width: 1500px) {
  .hero-explorer-container {
    padding-top: 10vw;
  }

  .hero-type-heading {
    font-size: 4vw;
  }

  .hero-type-text {
    font-size: 2.5vw;
    padding: 0 5%;
  }

}


