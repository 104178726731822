@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

.cars-container {
    /* height:4000px; */
    width:100%;
    position: relative;
    overflow: hidden; 
}

.main-car-map-container {
  position: relative;
  width: 60%;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 8.5vw;
  padding-bottom: 5vw;
}

.main-car-map {
  width: 100%;
  height: auto;
  display: block;
}

/*.car-teams {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: rgb(206, 205, 205);
  font-size: 1vw;
  position: absolute;
  text-align: center;
  white-space: nowrap;
  overflow: visible;
}

.aerodynamics-text{
  top: 18%;
}

.suspension-text {
  top: 39%;
  left: -21%;
}

.controls-text {
  top: 53.5%;
  left: -6.5%;
}

.drivetrain-text {
  top: 64%;
  left: -14%;
}

.brakes-text {
  top: 80%;
  left: -24%;
}

.chassis-text {
  top: 89.5%;
  left: 32%;
}

.composite-text {
  top: 89.5%;
  left: 60%;
}

.voltage-text {
  top: 74.5%;
  left: 98%;
}

.accumulator-text {
  top: 51%;
  left: 101%;
}

.ergonomics-text {
  top: 36%;
  left: 101%;
}

.machining-text {
  top: 18%;
  left: 79%;
}*/

@media only screen and (max-width: 600px) {
    .main-car-map-container {
      width: 90%;
    }
  
    /*.car-teams {
      font-size: 2vw;
    }*/
}


@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .main-car-map-container {
      width: 93%;
    }
  
    /*.car-teams {
      font-size: 1.5vw;
    }*/
}
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .main-car-map-container {
    width: 75%;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .main-car-map-container {
    width: 68%;
  }
}
@media screen and (min-width: 1601px) {
  .main-car-map-container {
    width: 70%;
  }
}