.why-join-container {
    min-height: calc(100vh - 770px)
}



.showbox {
    padding: 30px 0 50px;
}

.discover-passion-header {
    padding: 45px;
    font-size: 35px;
}

.why-join-intro-paragraph-container {
    padding-top: 30px;
    /* border:1px solid red; */
    width: 90%;
    max-width: 920px;
    margin: auto;
    /* height:500px; */
    font-size: 17px;
    text-align: justify;
    line-height: 1.5em;
}

.why-join-intro-paragraph {
    color: #fff;
    text-align: center;
}

@media only screen and (max-width: 800px) {

    .why-join-container {
        /* background: #222; */

    }



    .showbox {
        /* background: #fff; */
        padding: 0px 0 50px;
        /* min-height: calc(100vh - 340px) */
    }

    .discover-passion-header {
        padding: 45px;
        font-size: 20px;
    }

    .why-join-intro-paragraph-container {
        padding-top: 20px;
        /* border:1px solid red; */
        width: 90%;
        max-width: 920px;
        margin: auto;
        /* height:500px; */
        font-size: 12px;
        text-align: left;
        line-height: 1.5em;
    }
}