.contact-page-container{
  width:100%;
  height:100%;
}


#contact-showcase{
  width:100%;
  height:100vh;
  position: relative;
  opacity: 0;
  transition: 2s ease-out;
  /* border:1px solid red; */
}

#contact-image{
  width:100%;

  opacity: .3;
  position: absolute;
  top:0;
}
.contact-showcase-gradient{
  position: absolute;
  width:100%;
  height: 30%;
  bottom:0;
  background-image: linear-gradient( rgba(0,0,0,0), rgb(0, 0, 0));
}

.contact-title{
  color:#fff;
  position: relative;
  padding-top:150px;
  padding-bottom:40px;
  /* border:1px solid red; */
  text-align: center;
  font-size:100px;
  font-family: 'Rajdhani', sans-serif;
}

.contact-text-container {
  position: relative;
  width:1000px;
  margin:0 auto;
  /* border:1px solid green; */
  display:flex;

}

.contact-email-container{
  position: relative;
  width:45%;
}
.contact-email {
  color:#fff;
  font-size:40px;
  font-weight:100;
}

.contact-social-media-container{
  position: relative;
  margin-left: 10%;
  /* border:1px solid blue; */
  width:60%;
}

.social-media-row{
  /* border:1px solid red; */
  margin-bottom:30px;
  padding-bottom:30px;
  position: relative;
  width:auto;
  border-bottom: 1px solid #888;
}

.social-media-row:hover{
  cursor: pointer;
}
.social-media-title{
  color:#fff;
  font-weight:100;
  padding:0;
  font-size: 20px;
  
}
.social-media-title span{
  float:right;
  font-weight: 400;
  transition:1s ease-in-out;
  color:#bbb;
  font-size: 25px;
}
.social-media-row:hover .social-media-title span{
  color:#fff;
}

.social-media-white-underline{
  background-color:#fff;
  width:0;
  position: absolute;
  bottom:-1px;
  height:2px;
  transition:.6s ease-in-out;
  opacity: 0.5;
}
.social-media-row:hover .social-media-white-underline{
  width:100%;
  opacity: 1;
}

@media only screen and (max-width: 1200px) {
  /* *{border:1px solid blue;} */
  .contact-page-container{
  }
  
  
  #contact-showcase{
  }
  
  #contact-image{
  }
  .contact-showcase-gradient{
  }
  
  .contact-title{
    padding-top:14vw;
    padding-bottom:3vw;
    font-size:8vw;
  }
  
  .contact-text-container {
    width:90%;
  }
  
  .contact-email-container{
  }
  .contact-email {
    color:#fff;
    font-size:3.5vw;
  }
  
  .contact-social-media-container{
  }
  
  .social-media-row{
    /* border:1px solid red; */
    margin-bottom:2.5vw;
    padding-bottom:2.5vw;
  }
  
  .social-media-row:hover{
  }
  .social-media-title{
    font-size: 1.8vw;
    
  }
  .social-media-title span{
    font-size: 2vw;
  }
  .social-media-row:hover .social-media-title span{
  }
  
  .social-media-white-underline{
    bottom:-.1vw;
    height:.2vw;
  }
  .social-media-row:hover .social-media-white-underline{
  }
}
