.video-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    margin-top: 8rem;
    gap: 6rem;
    position: relative; 
    text-align: center;
}

.video-text {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    color: white;
    font-size: 2rem;
    text-align: center;
    overflow: visible;
}

.phone-video {
    position: relative;
    align-items: center;
    width: 23vw;
}

@media only screen and (max-width: 1280px) {
    .video-container {
        gap: 4rem;
    }

    .phone-video {
        width: 30vw;
    }
}

@media only screen and (max-width: 1024px) {
    .video-container {
        gap: 3rem;
    }

    .video-text {
        font-size: 1.7rem;
    }

    .phone-video {
        width: 45vw;
    }
}

@media only screen and (max-width: 600px) {
    .video-container {
        flex-direction: column;
        gap: 2rem;
    }

    .video-text {
        font-size: 1.8rem;
        margin: 0 1.3rem;
        white-space: normal;
    }

    .phone-video {
        width: 60vw;
    }
}


