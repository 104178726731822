#team-showcase-container {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  opacity: 0;
  transition: 1s;
}

#team-showcase-image {
  width: 100%;
  left: 0;
  height: 100vh;
  transition: 4s ease-out;
  object-fit: cover;
  position: relative;
  transform: scale(1.35) translateY(-7%);
  object-position: center top;
}

.team-showcase-gradient {
  position: absolute;
  width: 100%;
  height: 40%;
  top: 0;
  left: 0;
  background-image: linear-gradient(to bottom, rgb(0, 0, 0) 7%, rgba(0, 0, 0, 0));
}

#team-showcase-text-container {
  position: absolute;
  width: 100%;
  top: 20%;
  left: 50%;

}

.team-showcase-text {
  color: #fff;
  font-size: 80px;
  text-align: center;
  font-family: 'Rajdhani', sans-serif;
}

.team-showcase-title-container {
  width: 100%;
  text-align: center;
}

.team-showcase-title {
  color: #fff;
  font-size: 8rem;
  font-weight: 600;
  text-align: center;
  font-family: 'Inter', sans-serif;
  margin: 0 auto;
  width: 90%;
}

.team-showcase-blurb {
  color: #ddd;
  font-size: 27px;
  line-height: 1.4em;
  font-weight: 100;
  text-align: center;
}



@media only screen and (max-width: 1200px) {

  #team-showcase-container {
    height: 100vh;
  }

  #team-showcase-image {
    width: 100%;
    left: 0;
    height: 100vh;
    object-fit: cover;
  }

  .team-showcase-gradient {
    position: absolute;
    width: 100%;
    height: 50%;
    top: 0;
    left: 0;
    bottom: auto;
    background-image: linear-gradient(to bottom, rgb(0, 0, 0) 20%, rgba(0, 0, 0, 0));
  }

  #team-showcase-text-container {
    position: absolute;
    width: 75vw;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    transition: 1s;
    margin: 0;
    z-index: 10;
  }

  .team-showcase-text {
    font-size: 9vw;
  }

  .team-showcase-blurb {
    font-size: 2.4vw;
  }

  .team-showcase-title {
    font-size: 6rem;
  }

}

@media only screen and (max-width: 1000px) {
  .team-showcase-title {
    font-size: 5rem;
  }
}

/* Mobile devices */
@media only screen and (max-width: 768px) {
  #team-showcase-container {
    height: 100vh;
    position: relative;
  }
  
  #team-showcase-image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    transform: scale(1);
    object-position: center center;
  }
  
  .team-showcase-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    bottom: auto;
    background-image: linear-gradient(to bottom, rgb(0, 0, 0) 20%, rgba(0, 0, 0, 0));
  }
  
  #team-showcase-text-container {
    width: 100%;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .team-showcase-title-container {
    width: 100%;
    text-align: center;
  }
  
  .team-showcase-title {
    font-size: 3.5rem;
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }
}

/* Small mobile devices */
@media only screen and (max-width: 480px) {
  .team-showcase-title {
    font-size: 2.8rem;
  }
}

/* iPhone specific adjustments */
@media only screen and (max-width: 428px) {
  #team-showcase-text-container {
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .team-showcase-title {
    font-size: 2.8rem;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    text-align: center;
  }
}