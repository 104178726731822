.team-page-container{
  width:100%;
  overflow: hidden;
}

.team-photo-container{
  width:100%;
  height:auto;
  position: relative;
}

.team-photo {
  position: relative;
  width:100%;
}