body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scroll */
  width: 100%;
}

.home-page-component {
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
}

.homepage-header {
    opacity: 0;
}

    #about-section-container{
        width:800px;
        height:300px;
        margin:0 auto;
        padding-top:100px;
        opacity:0;
        transition:2s;
    }

    #about-section-container h2 {
        color:#999;
        font-size:25px;
        text-align: center;
        font-weight: 100;
        line-height: 1.4em;
    }

    #about-section-container h2 span{
        font-weight: 400;
        color:#ccc;
    }

.play-button{
    position: relative;
    top:-100px;
    width: 240px;
    margin-left: 65px;
    /* border:2px solid blue; */
    transition:.5s;
    opacity:.7;
  }
  .play-button:hover{
    cursor: pointer;
    width:200px;
    margin-left:85px;
    
    opacity:1;
  }


  @media only screen and (max-width: 1000px) {
    
        #about-section-container{
            height:25vw;
            padding-top:8vw;
            width:100%;
        }
    
        #about-section-container h2 {
            font-size:2vw;
        }
    
    

  }
  @media only screen and (max-width: 1500px) {
    .play-button{
        top:-1vw;
        width: 20vw;
        margin-left: -3vw;
      }
      .play-button:hover{
        width:20vw;
        margin-left:-3vw;
      }
  }

  @media only screen and (max-width: 800px) {
    #about-section-container{
        height:50vw;
    }
    #about-section-container h2 {
        font-size:4vw;
    }
  }

  /* Additional responsive fixes */
  @media only screen and (max-width: 768px) {
    #about-section-container {
      width: 100%;
      padding: 50px 20px;
      box-sizing: border-box;
    }
    
    #about-section-container h2 {
      font-size: 18px;
    }
    
    .play-button {
      width: 180px;
      margin-left: 0;
      top: 0;
    }
    
    .play-button:hover {
      width: 180px;
      margin-left: 0;
    }
  }
  
  @media only screen and (max-width: 480px) {
    #about-section-container h2 {
      font-size: 16px;
    }
    
    .play-button {
      width: 150px;
    }
    
    .play-button:hover {
      width: 150px;
    }
  }