@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

/*-------------------Section 2-------------------*/


.row1-cover-container{
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 7vw;
  margin-left: 5vw;
  margin-right: 15vw;
}

.team-item {
  position: relative;
  display: inline-block;
}


.team-cover {
  width: 17rem;
  height: 17rem;
  margin-bottom: 1vw;
  border-radius: 50%;
  border: 7px solid #9B1C22;
  object-fit: cover;
  opacity: 0.7 ;
  transition: transform 0.3s ease;
  filter: sepia(50%) hue-rotate(-20deg) saturate(150%);
}

.team-cover:hover {
  transform: scale(1.03);
  box-shadow: 0 0 5px 5px rgb(206, 205, 205);
  cursor: pointer;
}

.team-text-cover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: rgb(230, 229, 229);
  pointer-events: none;
  white-space: wrap;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  text-align: center;
}


/*-------------------Section 3-------------------*/


.info-cover-container {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10vw;
  margin-left: 2vw;
  margin-right: 2vw;
}

.info-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.info-item .info-cover {
  width: 7rem;
  height: 7rem;
  margin-bottom: 1vw;
  border-radius: 50%;
  border: 4px solid #9B1C22;
  object-fit: cover;
  opacity: 0.55;
  transition: transform 0.3s ease;
  filter: sepia(50%) hue-rotate(-20deg) saturate(150%);
}

.info-item.active .info-cover {
  width: 8rem;
  height: 8rem;
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
}

.info-text-cover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.8rem;
  color: rgb(230, 229, 229);
  pointer-events: none;
  white-space: wrap;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  text-align: center;
}

.info-item.active .info-text-cover {
  transform: translate(-50%, -50%) scale(1.2); /* Scale the text */
  font-size: 0.9rem; 
}

.info-content {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  line-height: 1.5em;
  color: rgb(206, 205, 205);
  font-size: 1.4vw;
  position: relative;
  text-align: center;
  display: block;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 3vw;
  margin-bottom: 10vw;
  border: 2px solid white;
  padding: 2vw;
}

.info-content h2 {
  font-size: 2vw;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  line-height: 2em;
  color: white;
}

.section-content {
  display: none;
}

.section-content.active {
  display: block;
}

.info-content-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.info-content-wrapper p{
  flex: 3;
}

.accumulator-content-image {
  position: relative;
  display: flex;
  width: 20rem;
  height: 25rem;
  max-width: 600px;
  border-radius: 10px;
}

.aerodynamics-content-image {
  position: relative;
  display: flex;
  width: 25rem;
  height: 25rem;
  max-width: 600px;
  border-radius: 10px;
}

.brakes-content-image {
  position: relative;
  display: flex;
  width: 30rem;
  height: 25rem;
  max-width: 600px;
  border-radius: 10px;
}

.business-content-image {
  position: relative;
  display: flex;
  width: 25rem;
  height: 25rem;
  max-width: 600px;
  border-radius: 10px;
}

.chassis-content-image {
  position: relative;
  display: flex;
  width: 30rem;
  height: 25rem;
  max-width: 600px;
  border-radius: 10px;
}

.composites-content-image {
  position: relative;
  display: flex;
  width: 19rem;
  height: 25rem;
  max-width: 600px;
  border-radius: 10px;
}

.controls-content-image {
  position: relative;
  display: flex;
  width: 30rem;
  height: 18rem;
  max-width: 600px;
  border-radius: 10px;
}

.drivetrain-content-image {
  position: relative;
  display: flex;
  width: 25rem;
  height: 25rem;
  max-width: 600px;
  border-radius: 10px;
}

.ergonomics-content-image {
  position: relative;
  display: flex;
  width: 25rem;
  height: 25rem;
  max-width: 600px;
  border-radius: 10px;
}

.low_highvoltage-content-image {
  position: relative;
  display: flex;
  width: 25rem;
  height: 35rem;
  max-width: 600px;
  border-radius: 10px;
}

.machining-content-image {
  position: relative;
  display: flex;
  width: 30rem;
  height: 23rem;
  max-width: 600px;
  border-radius: 10px;
}

.suspension-content-image {
  position: relative;
  display: flex;
  width: 35rem;
  height: 25rem;
  max-width: 600px;
  border-radius: 10px;
}

/* Responsive for max-width 600px */
@media only screen and (max-width: 600px) {

  .row1-cover-container{
    margin-left: 8vw;
    margin-right: 8vw;
    justify-content: center;
  }

  .team-cover {
    width: 12rem;
    height: 12rem;
  }

  .team-text-cover{
    font-size: 4vw;
  }

  .info-cover-container {
    justify-content: center;
    gap: 10px;
  }

  .info-item .info-cover {
    width: 5rem;
    height: 5rem;
  }

  .info-item.active .info-cover {
    width: 6rem;
    height: 6rem;
  }

  .info-text-cover {
    font-size: 0.6rem;
  }

  .info-item.active .info-text-cover {
    font-size: 0.7rem;
  }

  .info-content {
    font-size: 3vw;
  }

  .info-content h2 {
    font-size: 4vw;
  }

  .info-content-wrapper {
    flex-direction: column;
    gap: 10px;
  }

  .accumulator-content-image,
  .aerodynamics-content-image,
  .brakes-content-image,
  .business-content-image,
  .chassis-content-image,
  .composites-content-image,
  .controls-content-image,
  .drivetrain-content-image,
  .ergonomics-content-image,
  .low_highvoltage-content-image,
  .machining-content-image,
  .suspension-content-image {
    width: 60%;
    margin: 3vw 3vw;
    height: auto;
  }
}

/* Responsive styles for min-width 601px and max-width 1024px */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .row1-cover-container {
    margin-left: 2vw;
    margin-right: 2vw;
    justify-content: center;
    column-gap: 3vw;
    row-gap: 1.5vw;
  }

  .team-cover {
    width: 15rem;
    height: 15rem;
  }

  .team-text-cover {
    font-size: 3vw;
  }

  .info-cover-container {
    justify-content: center;
    gap: 10px;
  }

  .info-item .info-cover {
    width: 6rem;
    height: 6rem;
  }

  .info-item.active .info-cover {
    width: 7rem;
    height: 7rem;
  }

  .info-text-cover {
    font-size: 0.7rem;
  }

  .info-item.active .info-text-cover {
    font-size: 0.8rem;
  }

  .info-content {
    font-size: 3vw;
  }

  .info-content h2 {
    font-size: 4vw;
  }

  .info-content-wrapper {
    flex-direction: column;
    gap: 10px;
  }

  .accumulator-content-image,
  .aerodynamics-content-image,
  .brakes-content-image,
  .business-content-image,
  .chassis-content-image,
  .composites-content-image,
  .controls-content-image,
  .drivetrain-content-image,
  .ergonomics-content-image,
  .low_highvoltage-content-image,
  .machining-content-image,
  .suspension-content-image {
    width: 60%;
    height: auto;
    margin: 3vw 3vw;
  }
}

/* Responsive styles for min-width 1025px and max-width 1280px */
@media only screen and (min-width: 1025px) and (max-width: 1280px) {

  .row1-cover-container {
    margin-left: 3vw;
    margin-right: 3vw;
    justify-content: center;
    column-gap: 4vw;
    row-gap: 1vw;
  }

  .team-cover {
    width: 17rem;
    height: 17rem;
  }

  .team-text-cover {
    font-size: 2.5vw;
  }

  .info-cover-container {
    justify-content: center;
    gap: 15px;
  }

  .info-item .info-cover {
    width: 7rem;
    height: 7rem;
  }

  .info-item.active .info-cover {
    width: 8rem;
    height: 8rem;
  }

  .info-text-cover {
    font-size: 0.8rem;
  }

  .info-item.active .info-text-cover {
    font-size: 0.9rem;
  }

  .info-content {
    font-size: 2vw;
  }

  .info-content h2 {
    font-size: 3vw;
  }

  .info-content-wrapper {
    flex-direction: column;
    gap: 10px;
  }

  .accumulator-content-image,
  .aerodynamics-content-image,
  .brakes-content-image,
  .business-content-image,
  .chassis-content-image,
  .composites-content-image,
  .controls-content-image,
  .drivetrain-content-image,
  .ergonomics-content-image,
  .low_highvoltage-content-image,
  .machining-content-image,
  .suspension-content-image {
    width: 45%;
    height: auto;
    margin: 3vw 3vw;
  }
}

/* Responsive styles for min-width 1281px and max-width 1600px */
@media only screen and (min-width: 1281px) and (max-width: 1400px) {

  .row1-cover-container {
    margin-left: 4vw;
    margin-right: 4vw;
    justify-content: center;
    column-gap: 5vw;
    row-gap: 2vw;
  }

  .team-cover {
    width: 18rem;
    height: 18rem;
  }

  .team-text-cover {
    font-size: 2vw;
  }

  .info-cover-container {
    justify-content: center;
    gap: 20px;
  }

  .info-item .info-cover {
    width: 8rem;
    height: 8rem;
  }

  .info-item.active .info-cover {
    width: 9rem;
    height: 9rem;
  }

  .info-text-cover {
    font-size: 0.9rem;
  }

  .info-item.active .info-text-cover {
    font-size: 1rem;
  }

  .info-content {
    font-size: 1.5vw;
  }

  .info-content h2 {
    font-size: 3vw;
  }


  .accumulator-content-image,
  .aerodynamics-content-image,
  .brakes-content-image,
  .business-content-image,
  .chassis-content-image,
  .composites-content-image,
  .controls-content-image,
  .drivetrain-content-image,
  .ergonomics-content-image,
  .low_highvoltage-content-image,
  .machining-content-image,
  .suspension-content-image {
    width: 40%;
    height: auto;
  }
}


@media (max-width: 1400px) { /* Resets on smaller screens */
  .team-item:nth-child(5),
  .team-item:nth-child(6),
  .team-item:nth-child(7),
  .team-item:nth-child(8) {
    transform: translateX(0); /* Removes the shift */
  }
}

@media (min-width: 1401px) { /* Shift only on larger screens */
  .team-item:nth-child(5),
  .team-item:nth-child(6),
  .team-item:nth-child(7),
  .team-item:nth-child(8) {
    transform: translateX(10vw); /* Shifts only the middle row */
  }
}

@media screen and (min-width: 1601px) {
  .row1-cover-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 85%;
    margin-left: 50px;
    margin-right: 50px;
    justify-content: space-between;
  }

  .team-cover {
    width: 20rem;
    height: 20rem;
  }

  .team-text-cover {
    font-size: 1.5vw;
  }

  .info-cover-container {
    justify-content: center;
    gap: 25px;
  }

  .info-item .info-cover {
    width: 15rem;
    height: 15rem;
  }

  .info-item.active .info-cover {
    width: 17rem;
    height: 17rem;
  }

  .info-text-cover {
    font-size: 1.5rem;
  }

  .info-item.active .info-text-cover {
    font-size: 1.1rem;
  }

  .info-content {
    font-size: 1.5vw;
  }

  .info-content h2 {
    font-size: 2.5vw;
  }

  .accumulator-content-image,
  .aerodynamics-content-image,
  .brakes-content-image,
  .business-content-image,
  .chassis-content-image,
  .composites-content-image,
  .controls-content-image,
  .drivetrain-content-image,
  .ergonomics-content-image,
  .low_highvoltage-content-image,
  .machining-content-image,
  .suspension-content-image {
    width: 35%;
    height: auto;
  }

  .team-item:nth-child(5),
  .team-item:nth-child(6),
  .team-item:nth-child(7),
  .team-item:nth-child(8) {
    transform: translateX(50%); /* Shifts only the middle row */
  }
}