.meet-team-section {
  width: 100%;
  background-color: black;
  padding: 0;
  position: relative;
  min-height: 50vh;
}

.meet-team-container {
  max-width: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.meet-team-content {
  flex: 0 0 50%;
  padding: 0 60px 0 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 700px;
}

.meet-team-title {
  font-size: 4rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: white;
  font-family: 'Inter', sans-serif;
  position: relative;
  width: 90%; /* Match paragraph width */
  text-align: center; /* Align left to match paragraph */
  align-self: flex-start; /* Align with left edge */
}

.meet-team-title::after {
  content: '';
  display: block;
  width: 80%;
  height: 4px;
  background-color: #911a1e;
  margin-top: 15px;
  margin-bottom: 30px;
  justify-self: center;
}

.meet-team-text {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 40px;
  color: white;
  font-family: 'Inter', sans-serif;
  width: 90%;
}

.meet-team-button-container {
  display: flex;
  width: 90%; /* Match paragraph width */
  justify-content: center; /* Center the button */
  margin-top: 10px;
}

.meet-team-button {
  display: inline-block;
  padding: 18px 50px; 
  font-size: 1.7rem;
  font-weight: 500;
  color: #fff;
  background-color: transparent;
  border: 2px solid #911a1e;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.meet-team-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #911a1e;
  transition: left 0.4s ease-in-out;
  z-index: -1;
}

.meet-team-button:hover {
  color: #fff; /* Keep text white */
}

.meet-team-button:hover::before {
  left: 0;
}

/* Remove the previous hover effect */
.meet-team-button:hover {
  background-color: transparent;
  transform: none;
}

.meet-team-image-container {
  flex: 0 0 50%;
  overflow: hidden;
  position: relative;
  height: auto;
}

.meet-team-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* Responsive styles */
@media only screen and (max-width: 1000px) {
  .meet-team-container {
    flex-direction: column; /* Changed from column-reverse to have text first */
  }

  .meet-team-content {
    flex: 0 0 100%;
    padding: 60px 40px;
    align-items: center;
    text-align: center;
    order: 1; /* Ensure text content appears first */
  }

  .meet-team-title {
    width: 100%;
    max-width: 100%;
    text-align: center;
  }

  .meet-team-title::after {
    margin-left: auto;
    margin-right: auto;
  }
  
  .meet-team-text {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .meet-team-image-container {
    flex: 0 0 100%;
    height: 500px;
    order: 2; /* Ensure image appears second/below */
  }
}

@media only screen and (max-width: 768px) {
  .meet-team-content {
    padding: 50px 30px;
    min-height: auto;
  }

  .meet-team-title {
    font-size: 3.2rem;
  }

  .meet-team-text {
    font-size: 1.15rem;
  }

  .meet-team-image-container {
    height: 400px;
  }
}

@media only screen and (max-width: 480px) {
  .meet-team-title {
    font-size: 2.5rem;
  }
  
  .meet-team-title::after {
    width: 100%;
  }

  .meet-team-text {
    font-size: 1rem;
  }

  .meet-team-button {
    padding: 14px 35px;
    font-size: 1.1rem;
  }

  .meet-team-image-container {
    height: 300px;
  }
}