.work-with-us-section {
  width: 100%;
  background-color: black;
  padding: 0;
  position: relative;
  min-height: 50vh;
}

.work-with-us-container {
  max-width: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.work-with-us-content {
  flex: 0 0 50%;
  padding: 0 100px 0 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 700px;
}

.work-with-us-title {
  font-size: 4rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: white;
  font-family: 'Inter', sans-serif;
  position: relative;
}

.work-with-us-title::after {
  content: '';
  display: block;
  width: 100%;
  height: 4px;
  background-color: #911a1e;
  margin-top: 15px;
  margin-bottom: 30px;
}

.work-with-us-text {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 20px;
  color: white;
  font-family: 'Inter', sans-serif;
  max-width: 600px;
}

.work-with-us-button-container {
  margin-top: 20px;
  display: flex;
}

.work-with-us-button {
  display: inline-block;
  padding: 20px 30px;
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  background-color: transparent;
  border: 2px solid #911a1e;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.work-with-us-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #911a1e;
  transition: left 0.4s ease-in-out;
  z-index: -1;
}

.work-with-us-button:hover {
  color: #fff; /* Keep text white */
}

.work-with-us-button:hover::before {
  left: 0;
}

/* Remove the previous hover effect */
.work-with-us-button:hover {
  background-color: transparent;
  transform: none;
}

.work-with-us-image-container {
  flex: 0 0 50%;
  overflow: hidden;
  position: relative;
  height: auto;
}

.work-with-us-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* Responsive styles */
@media only screen and (max-width: 992px) {
  .work-with-us-container {
    flex-direction: column;
  }

  .work-with-us-content {
    flex: 0 0 100%;
    padding: 60px 40px;
    align-items: center;
    text-align: center;
    order: 1;
  }

  .work-with-us-title {
    text-align: center;
  }

  .work-with-us-title::after {
    margin-left: auto;
    margin-right: auto;
  }

  .work-with-us-image-container {
    flex: 0 0 100%;
    height: 500px;
    order: 2;
  }
}

@media only screen and (max-width: 768px) {
  .work-with-us-content {
    padding: 50px 30px;
    min-height: auto;
  }

  .work-with-us-title {
    font-size: 3.2rem;
  }

  .work-with-us-text {
    font-size: 1.15rem;
  }

  .work-with-us-image-container {
    height: 400px;
  }
}

@media only screen and (max-width: 480px) {
  .work-with-us-title {
    font-size: 2.5rem;
  }

  .work-with-us-text {
    font-size: 1rem;
  }

  .work-with-us-button {
    padding: 14px 25px;
    font-size: 1rem;
  }

  .work-with-us-image-container {
    height: 300px;
  }
}