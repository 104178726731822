.landing-ether {
    height: 100vh; /* Full viewport height */
    width: 100%;
    background-color: #000; /* Fallback color */
    background-size: 120% auto; /* Zoom in to 120% */
    background-position: center 80%;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden; /* Prevent any content overflow */
}

/* Adjust overlay for better visibility on all screen sizes */
.landing-ether::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1); /* Low overlay for better visibility */
    z-index: 1;
}

#landing-page-container {
    width: 100%;
    height: 100vh; /* Full viewport height */
    display: flex;
    flex-direction: column; /* Change to column to handle mobile layout better */
    align-items: flex-start; /* Align to left instead of center */
    justify-content: flex-start; /* Change from center to flex-start */
    position: relative;
    z-index: 2;
}

.title-container {
    width: 90%;
    max-width: 1200px;
    position: absolute;
    left: 5vw;
    top: 25%;
    z-index: 5;
}

.main-title {
    color: #fff;
    font-size: 7rem;
    font-weight: 550;
    margin-bottom: 1.5rem;
    font-family: 'Inter', sans-serif;
    text-align: left; /* Ensure left alignment */
    width: 100%; /* Full width */
}

.subtitle {
    color: #fff;
    font-size: 3rem;
    font-weight: 400;
    margin-top: 0.5rem;
    font-family: 'Inter', sans-serif;
    text-align: left; /* Ensure left alignment */
    width: 100%; /* Full width */
}

#landing-image-container {
    width:65%;
    max-width:800px;
    height:100%;
    position: relative;
    margin:0px auto;
    top:100px;
    justify-content: center;
    opacity: 1; /* Set to visible by default */
    transition: none; /* Remove transition */
}

#landing-image{
    width:100%;
    opacity: 1;
    position: absolute;
    top:20px;
}

#landing-image-dark{
    width:100%;
    opacity: 1;
    position: absolute;
    top:20px;
    transition: 3s;
}

#landing-title-container {
    width:1200px;
    position: absolute;
    justify-content: center;
    top:360px; /* Set to final position by default */
    margin:0 calc(50vw - 600px);
    opacity: 1; /* Set to visible by default */
    transition: none; /* Remove transition */
}

.landing-title {
    color:#fff;
    margin:0 auto;
    font-size:100px;
    text-align: center;
    font-family: 'Rajdhani', sans-serif;
    font-weight: bold;
}

#landing-subtitle{
    color:#fff;
    margin-top:0px; /* Set to final position */
    font-size:32px;
    text-align: center;
    transition: none; /* Remove transition */
    font-weight: lighter;
}

.enter-arrow-container {
    position: absolute;
    bottom:0px;
    width:60px;
    height:120px;
    margin-left: calc(50vw - 30px);
    transition: .5s;
    opacity:0.7
}

.enter-arrow-container:hover{
    width:70px;
    margin-left: calc(50vw - 35px);
    opacity:1;
}

#enter-arrow {
    width:100%;
    transform: rotate(180deg);
    opacity: 0; /* Keep hidden since we're removing the animation */
    position: relative;
    top:-30px;
    transition: none; /* Remove transition */
}

#enter-arrow:hover{
    top:40px;
    cursor: pointer;
    opacity: 1;
}

/* Consolidated media queries */
@media only screen and (max-width: 1200px) {
    .landing-ether {
        background-size: cover; /* Cover instead of 120% auto to avoid stretching */
        background-position: 40% center; /* Move background to appropriate position */
    }
    
    .title-container {
        left: 5vw;
        top: 20%;
        width: 95%;
    }
    
    .main-title {
        font-size: 6rem;
    }
    
    .subtitle {
        font-size: 2.5rem;
    }
    
    #landing-image-container {
        width: 100%;
        height: 100%;
        position: relative;
        margin: 0px auto;
        top: calc(50vh - 30vw);
    }
    
    #landing-image,
    #landing-image-dark {
        top: 2vw;
    }
    
    #landing-title-container {
        width: 1200px;
        position: absolute;
        justify-content: center;
        top: 400px;
        margin: 0 calc(50vw - 600px);
        opacity: 0;
        transition: 1s;
    }
    
    #landing-subtitle {
        margin-top: 40px;
        transition: 1s;
    }
}

@media only screen and (max-width: 992px) {
    #landing-page-container {
        align-items: center;
        justify-content: center;
    }
    
    .title-container {
        text-align: center;
        padding: 0 5vw;
        margin-top: 0;
        left: 0;
    }
    
    .main-title {
        font-size: 4.5rem;
        text-align: center;
    }
    
    .subtitle {
        font-size: 2rem;
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    .landing-ether {
        background-position: 70% center;
    }
    
    .main-title {
        font-size: 4rem;
    }
    
    .subtitle {
        font-size: 1.8rem;
    }
    
    /* Mobile showcase styles (from max-width: 750px) */
    .landing-mobile {
        width: 90%;
        margin: 0 auto;
        height: 100vh;
    }
    
    .mobile-showcase-pic {
        object-fit: contain;
        width: 100%;
        margin-top: calc(50vh - 60vw);
    }
    
    #landing-title-container {
        position: absolute;
        width: 100%;
        margin: 0;
        left: 0;
    }
    
    .landing-title {
        font-size: 10vw;
    }
    
    #landing-subtitle {
        font-size: 4vw;
    }
    
    #mobile-landing-title-container {
        position: relative;
        top: -24vh;
    }
    
    .mobile-landing-title {
        font-family: 'Rajdhani', sans-serif;
        color: #fff;
        font-size: 9vw;
        text-align: center;
    }
    
    #mobile-landing-subtitle {
        color: #fff;
        font-size: 3vw;
        font-weight: 100;
        text-align: center;
    }
}

@media only screen and (max-width: 480px) {
    .landing-ether {
        background-position: 70% 30%;
    }
    
    .title-container {
        top: 19%;
    }
    
    .main-title {
        font-size: 3rem;
    }
    
    .subtitle {
        font-size: 1.5rem;
    }
}